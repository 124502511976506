<template>
  <div
    eagle-photo-selector="root"
    :multiple="multiple"
    :single="!multiple"
  >
    <!-- 單圖模式 -->
    <photoItem
      v-if="!multiple"
      :type="type"
      :photoManager="photoManager"
      :disabledRemove="disabledRemove"
      :options="options"
      :disabled="disabled"
      v-model="photo"
    ></photoItem>

    <!-- 多圖模式 -->

    <!-- 一次上傳多張圖片 -->
    <v-btn
      v-if="multiple"
      small
      class="mb-8"
      outlined
      color="green"
      :loading="multipleUploadLoading"
      @click="browserMultiPhoto"
    >
      <v-icon left>fa fa-image</v-icon>
      <span>{{'action.tinymce.upload_photos'| t}}</span>

      <invisible-photo-uploader
        ref="invisiblePhotoUploader"
        @onLoadingChange="onLoadingChange"
        @appendPhoto="appendPhoto"
        multiple
        :type="type"
      ></invisible-photo-uploader>
    </v-btn>

    <v-draggable
      v-if="multiple"
      v-bind="dragOptions"
      v-model="photo"
      class="row px-8"
      eagle-photo-selector="photo-list-container"
    >
      <photoItem
        v-for="(photoItem, index) in photo"
        :type="type"
        class="mr-4 mb-4"
        :draggable="draggable"
        :key="`photo-item-${index}`"
        :photoManager="photoManager"
        :disabledRemove="disabledRemove"
        :options="options"
        :disabled="disabled"
        v-model="photo[index]"
      ></photoItem>
    </v-draggable>
  </div>
</template>

<script lang="babel" type="text/babel">
import photoItem from './photoItem.vue'
export default {
  props: {
    disabledRemove: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: null,
    },
    value: {
      type: [Object, Array],
      default: () => null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    options: {
      type: Object,
      default: () => ({

      }),
    },
    photoManager: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    multipleUploadLoading: false,
    photo: null,
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      if(!this.multiple) {
        this.initSinglePhoto()
        return
      }

      this.initMultiPhoto()
    },
    initSinglePhoto() {
      this.photo = window.eagleLodash.cloneDeep(this.value)
    },
    initMultiPhoto() {
      let photo
      if(!Array.isArray(this.value)) {
        photo = []
      }

      else {
        photo = window.eagleLodash.cloneDeep(this.value)
      }

      if(this.total) {
        // 自動補滿圖片總量限制
        for(let index=0; index<this.total; index++) {
          if(photo[index] === undefined) {
            photo.push(null)
          }
        }
      }

      this.photo = photo
    },
    onLoadingChange(status) {
      this.multipleUploadLoading = status
    },
    browserMultiPhoto() {
      this.$refs.invisiblePhotoUploader.openFileBrowser()
    },
    async appendPhoto(newPhoto) {
      if(!Array.isArray(this.photo)) {
        this.initMultiPhoto()
        await this.$nextTick()
      }
      let photo = window.eagleLodash.cloneDeep(this.photo)
      photo.unshift(newPhoto)
      photo.pop()
      this.photo = photo
    },
  },
  computed: {
    quantity() {
      if(!this.photo) return 0
      if(!this.multiple) return 1
      if(!Array.isArray(this.photo)) return 0
      return this.photo.length
    },
    draggable() {
      if(!this.multiple) return false
      if(!Array.isArray(this.photo)) return false
      return this.photo.length > 1
    },
    dragOptions() {
      return {
        disabled: this.draggable != true,
        handle: '.photo-selector-draggable',
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.init()
      },
    },
    photo: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.value, this.photo)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.photo))
      },
    },
  },
  components: {
    photoItem,
  },
}
</script>

<style lang="sass" type="text/sass">
*[eagle-photo-selector="root"]
  &[single]
    width: 200px
</style>
